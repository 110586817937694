<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
   v-model="alert"
    >
      Section 8 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 8: Homeowner Info
                    </div >
                    <div class="display-2 font-weight-light">
                    Bathrooms
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner(s)"
                                    color="black"
                                 v-model="homeowners"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                 v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="16"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Phone"
                                    color="black"
                                 v-model="numbers"
                                />
                            </v-col>
                            <v-col
                            cols="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Emails"
                                    color="black"
                                 v-model="emails"
                                />
                            </v-col>
                          </v-row>
                    </v-container>
                  </v-form>
                  </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 8: Master Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col cols="12" md="3" ><v-subheader> {{checkbox_masterbath_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_6"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_2.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_7"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_3.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_8"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_4.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_9"></v-select></v-col>
                        <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_5.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_10"></v-select></v-col>
                        <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_6.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_11"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_7.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_12"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_8.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-text-field :readonly="permissions === 'read:homeowner'"  v-model="section.column_13"></v-text-field></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_9.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_14"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_10.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_15"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_11.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_16"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_12.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_17"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_13.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_18"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_14.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_19"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_15.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_20"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_16.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_21"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_17.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_22"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_18.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_23"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_19.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_24"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_20.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_25"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_21.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_26"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_22.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_27"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_23.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_28"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_24.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_29"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_25.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_30"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_26.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_31"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_27.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_32"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_28.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_33"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_29.toString()}} {{this.price_section_eight.column_39}} EACH</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_34"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_masterbath_text_30.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_35"></v-select></v-col>
                      </v-row>
                    </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 8: Main Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                          <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_36"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_2.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_37"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_3.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_38"></v-text-field></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_4.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_39"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_5.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_40"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_6.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_41"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_7.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_42"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_8.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_43"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_9.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_44"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_10.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_45"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_11.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_46"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_mbath_text_12.toString()}}</v-subheader></v-col>
                          <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_47"></v-select></v-col>
                        </v-row>
                    </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 8: 1/2 Bath Powder Room
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_1.toString()}}</v-subheader></v-col>
                            <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_48"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_2.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_49"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_3.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_50"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_4.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_51"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_5.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_52"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_6.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_53"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_hbath_text_7.toString()}} {{this.price_section_eight.column_39}} EACH</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_54"></v-select></v-col>
                      </v-row>
                    </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      Section 8: Basement Bath
                    </div >
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_1.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_55"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_2.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_56"></v-text-field></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_3.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_57"></v-select></v-col>
                           <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_4.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_58"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_5.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_59"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_6.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_60"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_7.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_61"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_8.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_62"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_9.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_63"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_10.toString()}}</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_64"></v-select></v-col>
                            <v-col cols="12" md="3"><v-subheader> {{checkbox_bbath_text_11.toString()}} {{this.price_section_eight.column_39}} EACH</v-subheader></v-col>
                        <v-col cols="12" md="3"><v-select :readonly="permissions === 'read:homeowner'" :items="['Yes','No','Undecided']" v-model="section.column_65"></v-select></v-col>
                        <v-col col="12" md="12">
                                <v-header>Notes:<v-text-field :readonly="permissions === 'read:homeowner'" v-model="section.column_76"/></v-header>
                            </v-col>
                      </v-row>
                      <v-row>
                     <v-dialog
                     v-model="dialog"
                        max-width="50%"
                      >
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </v-col>
                      </template> -->
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                             v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select :readonly="permissions === 'read:homeowner'"
                                   v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                           </v-dialog>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1" v-model="section.column_67"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date" v-model="section.column_68"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2" v-model="section.column_69"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date" v-model="section.column_70"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                      </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: 'HomeSelectionsInteriorSelections',
  data() {
    return {
      checkbox_masterbath_text_1: '1) SHOWER - DESIGN CONFIRMED BY HOMEOWNER',
      checkbox_masterbath_text_2: '2) STANDARD FIBERGLASS STALL',
      checkbox_masterbath_text_3: '3) TILE WALLS',
      checkbox_masterbath_text_4: '3-A) TILE/GLASS WALLS',
      checkbox_masterbath_text_5: '4) TILE PAN W/CURB',
      checkbox_masterbath_text_6: '5) TILE: TO BE DONE BY HOMEOWNER',
      checkbox_masterbath_text_7: '6) SHOWER BENCH, SIZE: STANDARD',
      checkbox_masterbath_text_8: '7) SHOWER HEAD, HEIGHT (Standard 77 inches):',
      checkbox_masterbath_text_9: '8) DUAL SHOWER HEADS',
      checkbox_masterbath_text_10: '9) SHOWER DOOR',
      checkbox_masterbath_text_11: '10) SHOWER DOOR: STANDARD W/BRUSHED NICKEL FRAME',
      checkbox_masterbath_text_12: '10-A) SHOWER DOOR OIL RUB BRONZE CLIPS',
      checkbox_masterbath_text_13: '11) SHOWER DOOR: ORDERED BY HOMEOWNER',
      checkbox_masterbath_text_14: '12) CONFIRM SHOWER DOOR ORDER (GLASS & MIRROR CO.)',
      checkbox_masterbath_text_15: '13) TUB DESIGN CONFIRMED: STANDARD',
      checkbox_masterbath_text_16: '14) WHIRLPOOL TUB or FREE STANDING',
      checkbox_masterbath_text_17: '15) TILE AROUND TUB: STANDARD',
      checkbox_masterbath_text_18: '16) TUB: TO BE DONE BY HOMEOWNER',
      checkbox_masterbath_text_19: '17) FLOORING: STANDARD-VINYL FLOOR MATERIAL',
      checkbox_masterbath_text_20: '18) TILE FLOORING',
      checkbox_masterbath_text_21: '19) TILE FLOORING: TO BE DONE BY HOMEOWNER',
      checkbox_masterbath_text_22: '20) VANITY TOP: STANDARD LAMINATE',
      checkbox_masterbath_text_23: '21) VANITY TOP: UPGRADE MATERIAL',
      checkbox_masterbath_text_24: '22) VANITY BOWLS SPREAD CONFIRMED W/(ULTIMATE CABINETRY)',
      checkbox_masterbath_text_25: '23) MIRRORS: STANDARD, FRAMELESS',
      checkbox_masterbath_text_26: '24) MIRRORS: ORDERED (GLASS & MIRROR)',
      checkbox_masterbath_text_27: '25) MIRRORS: FRAMED, ORDERED',
      checkbox_masterbath_text_28: '26) HOMEOWNER PROVIDE & INSTALL MIRRORS',
      checkbox_masterbath_text_29: '27) HOMEOWNER PROVIDE MIRRORS & BOULDER TO INSTALL AT A COST OF $',
      checkbox_masterbath_text_30: '28) HOMEOWNER PROVIDE & INSTALL TOWEL BAR & TOILET PAPER DISPENSER',


      checkbox_mbath_text_1: '1)	STANDARD FIBERGLASS SHOWER/TUB	',
      checkbox_mbath_text_2: '2)	TILE SURROUND SHOWER TUB	',
      checkbox_mbath_text_3: '3)	STANDARD SHOWER HEAD HEIGHT (Standard 77 inches)	',
      checkbox_mbath_text_4: '4)	SHOWER DOOR ',
      checkbox_mbath_text_5: '5)	FLOORING: STANDARD, VINYL	',
      checkbox_mbath_text_6: '6)	HOMEOWNER TO DO FLOORING	',
      checkbox_mbath_text_7: '7)	VANITY TOP: STANDARD, LAMINATE	',
      checkbox_mbath_text_8: '8)	VANITY TOP: UPGRADE MATERIAL	',
      checkbox_mbath_text_9: '9)	VANITY TOP SPREAD CONFIRMED (ULTIMATE CABINETRY)	',
      checkbox_mbath_text_10: '10)	MIRROR: STANDARD, FRAMELESS	',
      checkbox_mbath_text_11: '11)	MIRROR: ORDERED (GLASS & MIRROR)	',
      checkbox_mbath_text_12: '12)	MIRROR: FRAMED, ORDERED	',
      checkbox_mbath_text_13: '13)	HOMEOWNER PROVIDE & INSTALL MIRROR 	',
      checkbox_mbath_text_14: '14)	HOMEOWNER PROVIDE MIRRORS & BOULDER TO INSTALL AT A COST OF $',

      checkbox_hbath_text_1: '1)	VANITY TOP: STD LAMINATE	',
      checkbox_hbath_text_2: '2)	VANITY TOP: UPDGRADE MATERIAL	',
      checkbox_hbath_text_3: '3)	MIRROR STD FRAMELESS	',
      checkbox_hbath_text_4: '4)	MIRROR ORDERED (GLASS & MIRROR)	 ',
      checkbox_hbath_text_5: '5)	MIRROR FRAMED, ORDERED	',
      checkbox_hbath_text_6: '6)	HOMEOWNER PROVIDE & INSTALL MIRROR ',
      checkbox_hbath_text_7: '7)	HOMEOWNER PROVIDE MIRRORS & BOULDER TO INSTALL AT A COST OF $',

      checkbox_bbath_text_1: '1)	STANDARD FIBERGLASS SHOWER (48” X 36”)	',
      checkbox_bbath_text_2: '2)	STANDARD SHOWER HEAD HEIGHT (Standard 77 inches)	 ',
      checkbox_bbath_text_3: '3)	SHOWER DOOR ',
      checkbox_bbath_text_4: '4)	FLOORING: STANDARD, VINYL	',
      checkbox_bbath_text_5: '5)	VANITY TOP: STANDARD LAMINATE	',
      checkbox_bbath_text_6: '6)	VANITY TOP: UPGRADE MATERIAL	 ',
      checkbox_bbath_text_7: '7)	MIRROR STD FRAMELESS	',
      checkbox_bbath_text_8: '8)	MIRROR ORDERED (GLASS & MIRROR)	',
      checkbox_bbath_text_9: '9)	MIRROR FRAMED ORDERED	',
      checkbox_bbath_text_10: '10)	HOMEOWNER PROVIDE & INSTALL MIRROR	',
      checkbox_bbath_text_11: '11)	HOMEOWNER PROVIDE MIRRORS & BOULDER TO INSTALL AT A COST OF $',
      home_id: localStorage.getItem("homeid"),
      alert: false,
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      section_id: "8",
      add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      price_section_eight: {
        column_39: ''
      },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
        column_32:'',
        column_33:'',
        column_34:'',
        column_35:'',
        column_36:'',
        column_37:'',
        column_38:'',
        column_39:'',
        column_40:'',
        column_41:'',
        column_42:'',
        column_43:'',
        column_44:'',
        column_45:'',
        column_46:'',
        column_47:'',
        column_48:'',
        column_49:'',
        column_50:'',
        column_51:'',
        column_52:'',
        column_53:'',
        column_54:'',
        column_55:'',
        column_56:'',
        column_57:'',
        column_58:'',
        column_59:'',
        column_60:'',
        column_61:'',
        column_62:'',
        column_63:'',
        column_64:'',
        column_65:'',
        column_66:'',
        column_67:'',
        column_68:'',
        column_69:'',
        column_70:''
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
    created () {
    this.getSection()
      this.getHomePrice()
      this.getVendorEmail()
      this.getUserInfo()
    },
    methods:{

      getSection(){
          axios
          .get(process.env.VUE_APP_GET_SECTION_URL,
          {
          params: {
              home_id: this.home_id,
              section_id: this.section_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.section = JSON.parse(result['JsonDetails'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getUserInfo(){
          axios
          .get(process.env.VUE_APP_GET_USER_URL_INFO,
          {
            params: {
              home_id: this.home_id
          }
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.selected_user = result[0]
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getHomePrice(){
          axios
          .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
          {
          })
          .then(response => {

              if (response.data)
              {
                  var result = response.data
                  this.price_section_eight = JSON.parse(result['SectionEight']) || {}
              }
          })
          .catch(error => {
          console.log(error)
          })
      },
      getVendorEmail(){
        axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
        .then(response => {
          this.vendor_email = response.data
        })
        .catch(error => {
          console.log(error)
        })
      },
      updateSection(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
          })
          .catch(error => {
          console.log(error)
          })
      },
      sectionComplete(){
          var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
          }
          axios
          .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
          .then(response => {
              console.log(response.data)
              this.alert = true
              this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
          })
          .catch(error => {
          console.log(error)
          })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
      },
      close(){
        this.dialog = false
        this.$nextTick(() => {
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
        })
      },
      sendEmail(to,from,template,content,section){

      var email_body ={
        "to": to,
        "from": from,
        "template_name": template,
        "template_data": {
          "content": content,
          "section": section
        }
      }

      axios
      .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {
        console.log(error)
      })

      }
    },
  computed:{
    homeowners(){
      return `${this.selected_user.PrimaryFirstName} ${this.selected_user.PrimaryLastName} ${this.selected_user.SecondaryFirstName} ${this.selected_user.SecondaryLastName}`
      },
    emails(){
      return `${this.selected_user.PrimaryEmailAddress} & ${this.selected_user.SecondaryEmailAddress}`
      },
    numbers(){
      return `${this.selected_user.CellNumber} & ${this.selected_user.HomeNumber}`
    },
    homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
    },
    permissions() {
            return localStorage.getItem('permissions')
      },
  }
}
</script>